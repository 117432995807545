<template>
  <div>
    <trac-tab-window :sectionTitle="'Products'" :subLinks="subLinks" />
  </div>
</template>

<script>
import MixinForScrollToTop from "../../offline-module/mixins/TabbedMixin";
export default {
  mixins: [MixinForScrollToTop],
  data() {
    return {
      subLinks: [
        {
          name: "Products",
          path: "/productsinventory/myproducts",
          selected: false,
          routerWindowClassString: "bg-white mt-12 p-12 big-shadow",
          dataAttribute: "",
        },
        {
          name: "Categories",
          path: "/productsinventory/categories",
          selected: false,
          routerWindowClassString: "mt-12 p-2",
          dataAttribute: "",
        },
        // {
        //   name: "Sub Categories",
        //   path: "/productsinventory/subcategories",
        //   selected: false,
        //   routerWindowClassString: 'mt-12 p-2',
        //   dataAttribute: "",
        // },
        {
          name: "Options",
          path: "/productsinventory/options",
          selected: false,
          routerWindowClassString: "mt-12 p-2",
          dataAttribute: "",
        },
        {
          name: "Modifiers",
          path: "/productsinventory/modifiers",
          selected: false,
          routerWindowClassString: "mt-12 p-2",
          dataAttribute: "",
        },
        {
          name: "Tags",
          path: "/productsinventory/tags",
          selected: false,
          routerWindowClassString: "mt-12 p-2",
          dataAttribute: "",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
